import './_search-map-block.scss'

import L from 'leaflet';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen/Control.FullScreen.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/leaflet.markercluster.js';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet-gesture-handling';
import regionsData from 'JS/regions.json';

import {choicesSelect} from 'JS/components/choices.js';
import {post} from 'JS/components/_request.js'
import 'flatpickr/dist/flatpickr.min.css';
import flatpickr from "flatpickr";
import Choices from "../../../src/js/components/choices";

const searchMapBlock = async function () {
    const blocks = document.querySelectorAll('section.search-map-block')

    for (const block of blocks) {
        let itvangTheme = document.querySelector('.itvang-theme');
        //choices.js - select
        const elements = block.querySelectorAll('.choices-js');
        let choices;
        let flatPickerCalendar;
        if (elements) {
            elements.forEach(function (el, index) {
                choices = choicesSelect(el);
                el.choicesInstance = choices;

                const arrow = document.createElement('div');
                arrow.classList.add('arrow');
                arrow.classList.add('hidden');

                const checkScrollable = () => {
                    const dropdownList = el.closest('.choices').querySelector('.choices__list--dropdown');
                    const choicesList = dropdownList.querySelector('.choices__list');
                    if (choicesList.scrollHeight > choicesList.clientHeight) {
                        if (!dropdownList.contains(arrow)) {
                            dropdownList.appendChild(arrow);
                        }
                        arrow.classList.remove('hidden');
                    } else {
                        arrow.classList.add('hidden');
                    }
                };

                choices.passedElement.element.addEventListener('showDropdown', () => {
                    checkScrollable();
                    arrow.classList.remove('hidden');
                });

                choices.passedElement.element.addEventListener('hideDropdown', () => {
                    arrow.classList.add('hidden');
                });
                if (itvangTheme) {
                    if (el.classList.contains('multiselect')) {
                        let isDropdownActive = false;

                        function toggleDropdown () {
                            if (isDropdownActive) {
                                el.choicesInstance.hideDropdown();
                            } else {
                                el.choicesInstance.showDropdown();
                            }
                            isDropdownActive = !isDropdownActive;
                        }

                        function handleEvent (event) {
                            event.preventDefault();
                            event.stopPropagation();
                            toggleDropdown();
                        }

                        el.choicesInstance.containerInner.element.addEventListener('click', handleEvent);
                        el.choicesInstance.containerInner.element.addEventListener('touchend', handleEvent);
                    }

                }


                let isScrollingUp = false;
                let lastScrollTop = 0;

                // Track scroll direction
                const dropdownList = el.closest('.choices').querySelector('.choices__list--dropdown');
                const choicesList = dropdownList.querySelector('.choices__list');

                choicesList.addEventListener('scroll', () => {
                    const currentScrollTop = choicesList.scrollTop;

                    if (currentScrollTop > lastScrollTop) {
                        isScrollingUp = false;
                    } else if (currentScrollTop < lastScrollTop) {
                        isScrollingUp = true;
                    }

                    lastScrollTop = currentScrollTop;

                    if (currentScrollTop === 0) {
                        arrow.classList.remove('hidden');
                    } else {
                        const maxScrollTop = choicesList.scrollHeight - choicesList.clientHeight;
                        if (currentScrollTop >= maxScrollTop) {
                            arrow.classList.add('hidden');
                        } else {
                            arrow.classList.remove('hidden');
                        }
                    }
                });

                choices.passedElement.element.addEventListener('wheel', (event) => {
                    if (event.deltaY < 0) {
                        // Scrolling up
                        arrow.classList.remove('hidden');
                    } else if (event.deltaY > 0) {
                        // Scrolling down
                        const maxScrollTop = choicesList.scrollHeight - choicesList.clientHeight;

                        if (choicesList.scrollTop >= maxScrollTop) {
                            arrow.classList.add('hidden');
                        } else {
                            arrow.classList.remove('hidden');
                        }
                    }
                });

            })
        }

        if (itvangTheme) {
            let calendar = block.querySelector('.calendar-range-picker input[name="auction-date"]');
            flatPickerCalendar = calendar.flatpickr({
                locale: {
                    firstDayOfWeek: 0,
                    weekdays: {
                        shorthand: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
                        longhand: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"]
                    },
                    months: {
                        shorthand: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"],
                        longhand: ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
                    },
                    rangeSeparator: " - ",
                },
                mode: "range",
                showMonths: isMobile() ? 1 : 2,
                disableMobile: true,
                nextArrow: '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">\n' +
                    '<path d="M1.78906 11.0288L6.80906 5.99875L1.78906 0.96875" stroke="#1E1F24" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '</svg>',
                prevArrow: '<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">\n' +
                    '<path d="M6.21497 11.0288L1.19922 5.99875L6.21497 0.96875" stroke="#1E1F24" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                    '</svg>',
                dateFormat: 'd.m.Y',
                onClose: function (selectedDates, dateStr, instance) {
                    calendar.closest('.calendar-range-picker').querySelector('.clear-values').classList.remove('active');
                    if (selectedDates.length >= 1) {
                        if (!isMobile() && !block.classList.contains('search-view')) {
                            fetchPosts()
                        }
                        if (selectedDates.length === 1) {
                            instance.setDate([selectedDates[0], selectedDates[0]], true, 'd.m.Y');
                        } else if (selectedDates.length === 2) {
                            instance.setDate([selectedDates[0], selectedDates[1]], true, 'd.m.Y');
                        }
                        calendar.closest('.calendar-range-picker').querySelector('.clear-values').classList.add('active');
                    }

                }
            })
        }

        //leaflet.js map
        let map;
        let center = [56.137933, 10.668538];
        const mapContainer = block.querySelector('.map-container').id;
        let initialZoom = itvangTheme ? 7.5 : 7.2;
        if (itvangTheme) {
            initialZoom = 7
            center = [56.237933, 8.3];
            if (isMobile()) {
                center = [56.237933, 11.5];
                initialZoom = 6.8
            }
        } else {
            initialZoom = 7.2
        }

        // Initialize Leaflet map centered on Denmark
        function initializeMap () {
            if (map) {
                map.remove();
            }

            map = L.map(mapContainer, {
                center: center,
                zoom: isMobile() ? 9 : initialZoom,
                minZoom: isMobile() ? 6 : 7,
                maxZoom: 18,
                scrollWheelZoom: true,
                fullscreenControl: false,
                dragging: true,
                touchZoom: true,
                zoomControl: false,
                zoomDelta: 1,
                zoomSnap: 0.25,
                tap: false,
                gestureHandling: true,
                gestureHandlingOptions: {
                    text: {
                        touch: "Use two fingers to move the map",
                        scroll: "Use ctrl + scroll to zoom the map",
                        scrollMac: "Use \u2318 + scroll to zoom the map"
                    }
                }
            });

            L.control
                .fullscreen({
                    position: 'topright',
                    title: 'Full screen',
                    titleCancel: 'Exit fullscreen mode',
                    content: '',
                    forceSeparateButton: true,
                    forcePseudoFullscreen: true,
                    fullscreenElement: false
                })
                .addTo(map);
        }

        // Initialize the map for the first time
        if (!(isMobile() && !block.classList.contains('search-view'))) {
            initializeMap();
        }

        if (map) {
            map.on('enterFullscreen', function () {
                document.querySelector('body').classList.add('fullscreen-map');
                document.querySelector('header').classList.add('fullscreen-map');
                if (itvangTheme) {
                    map.setView([56.137933, 10.468538], initialZoom + 1);
                } else {
                    map.setZoom(initialZoom + 0.25);
                }
            });

            map.on('exitFullscreen', function () {
                document.querySelector('body').classList.remove('fullscreen-map');
                document.querySelector('header').classList.remove('fullscreen-map');
                if (itvangTheme) {
                    map.setView(center, initialZoom);
                } else {
                    map.setZoom(initialZoom);
                }
            });
        }

        function resetMap () {
            if (map) {
                map.setView(center, initialZoom);
            }
        }

        const satelliteLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Powered by <a href="https://www.esri.com/en-us/home">Esri</a>'
        });

        const baseLayer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'
        });

        if (map && itvangTheme) {
            map.addLayer(baseLayer);
        }

        let markers = [];
        let markerCluster;
        let currentRegionCode = '';
        let municipalityCode = '';
        let teritorryCode = '';
        let activeBaseLayer = itvangTheme ? 'Map' : 'Illustrative';
        const CustomLayerControl = L.Control.extend({
            options: {
                position: 'bottomright'
            },
            onAdd: function (map) {
                const container = L.DomUtil.create('div', 'custom-layer-control');

                const toggleButton = L.DomUtil.create('div', 'toggle-button', container);

                const layerPopup = L.DomUtil.create('div', 'layer-popup', container);

                layerPopup.classList.add('hide');

                const layerButtons = block.querySelectorAll('.layer-popup button');
                let mapLayerName = block.querySelector('.map-container').getAttribute('data-map') ? block.querySelector('.map-container').getAttribute('data-map') : 'Map';

                const baseLayerButton = L.DomUtil.create('button', itvangTheme ? 'active' : '', layerPopup);
                baseLayerButton.setAttribute('data-name', 'Map');
                baseLayerButton.innerHTML = mapLayerName;
                L.DomEvent.on(baseLayerButton, 'click', function () {
                    updateActiveButton(baseLayerButton);
                    map.addLayer(baseLayer);
                    map.removeLayer(satelliteLayer);
                    map.removeLayer(gjLayer);
                });

                let satelliteLayerName = block.querySelector('.map-container').getAttribute('data-satellite') ? block.querySelector('.map-container').getAttribute('data-satellite') : 'Satellite';
                const satelliteLayerButton = L.DomUtil.create('button', '', layerPopup);
                satelliteLayerButton.innerHTML = satelliteLayerName;
                satelliteLayerButton.setAttribute('data-name', 'Satellite');
                L.DomEvent.on(satelliteLayerButton, 'click', function () {
                    updateActiveButton(satelliteLayerButton);
                    map.addLayer(satelliteLayer);
                    map.removeLayer(baseLayer);
                    map.removeLayer(gjLayer);
                });

                let illustrativeLayerName = block.querySelector('.map-container').getAttribute('data-illustrative') ? block.querySelector('.map-container').getAttribute('data-illustrative') : 'Illustrative';
                const gjLayerButton = L.DomUtil.create('button', itvangTheme ? '' : 'active', layerPopup);
                gjLayerButton.innerHTML = illustrativeLayerName;
                gjLayerButton.setAttribute('data-name', 'Illustrative');
                L.DomEvent.on(gjLayerButton, 'click', function () {
                    updateActiveButton(gjLayerButton);
                    map.addLayer(gjLayer);
                    map.removeLayer(baseLayer);
                    map.removeLayer(satelliteLayer);
                });

                function updateActiveButton (activeButton) {
                    const buttons = block.querySelectorAll('.layer-popup button');
                    buttons.forEach(btn => btn.classList.remove('active'));
                    toggleButton.classList.remove('active');
                    activeButton.classList.add('active');
                    activeBaseLayer = activeButton.getAttribute('data-name');
                    block.querySelector('.layer-popup').classList.add('hide');
                    removeJurisdictionLayer();
                    if (activeBaseLayer === 'Illustrative') {
                        if (currentRegionCode) {
                            addSpecificRegionToMap(currentRegionCode)
                        }
                        if (teritorryCode) {
                            removeJurisdictionLayer();
                            addJurisdictionsToMap(teritorryCode)
                        }
                        if (municipalityCode) {
                            addJurisdictionsToMap('');
                            addSpecificRegionToMap('')
                            removeJurisdictionLayer();
                            addMunicipalityToMap(municipalityCode)
                        }
                    }
                }

                L.DomEvent.on(toggleButton, 'click', function () {
                    if (!layerPopup.classList.contains('hide')) {
                        layerPopup.classList.add('hide');
                        toggleButton.classList.remove('active');
                    } else {
                        layerPopup.classList.remove('hide');
                        toggleButton.classList.add('active')
                    }

                });

                return container;
            },
        });
        if (map) {
            // Add Custom Layer Control to the map
            map.addControl(new CustomLayerControl());

            L.control.zoom({
                zoomInText: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n' +
                    '<path d="M12.75 11.25V6H11.25V11.25H6V12.75H11.25V18H12.75V12.75H18V11.25H12.75Z" fill="#1E1F24"/>\n' +
                    '</svg>',
                zoomOutText: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n' +
                    '<path d="M18 11.25H6V12.75H18V11.25Z" fill="#1E1F24"/>\n' +
                    '</svg>'
            }).setPosition('bottomright').addTo(map);
        }

        if(map) {
            map.on('move', function(event) {
                if(block.querySelector('.new-today-auctions-wrap.mobile-hide') && !block.querySelector('.new-today-auctions-wrap.mobile-hide').classList.contains('under')) {
                    block.querySelector('.new-today-auctions-wrap.mobile-hide').classList.add('under');
                }
            });
            map.on('moveend', function(event) {
                if(block.querySelector('.new-today-auctions-wrap.mobile-hide') && block.querySelector('.new-today-auctions-wrap.mobile-hide').classList.contains('under')) {
                    block.querySelector('.new-today-auctions-wrap.mobile-hide').classList.remove('under');
                }
            });
        }

        let gjLayer;
        if (!isMobile()) {
            fetchPosts();
        } else if (isMobile() && !(block.classList.contains('search-view'))) {
            // fetchPosts();
        } else {
            fetchPosts();
        }

        // Function to clear all markers
        function clearMarkers () {
            if (markers && markerCluster) {
                markers.forEach(function (marker) {
                    map.removeLayer(marker);
                });
                map.removeLayer(markerCluster);
                markers = [];
            }
        }

        let regionStyle = {
            "color": "#F9F9FB",
            "weight": 2,
            "fillColor": "#CDCED7",
            "fillOpacity": 1
        };

        let municipalityStyle = {
            fillColor: "#00759B",
            fillOpacity: 1,
            stroke: false,
        };

        let jurisdictionStyle = {
            fillColor: "#00759B",
            fillOpacity: 1,
            stroke: false
        };
        let regionStyle2;

        if (itvangTheme) {
            regionStyle = {
                "color": "#F9F9FB",
                "weight": 2,
                "fillColor": "#bbdefb",
                "fillOpacity": 1
            };
            municipalityStyle = {
                fillColor: "#2196F3FF",
                fillOpacity: 1,
                stroke: false,
            };

            jurisdictionStyle = {
                fillColor: "#2196F3FF",
                fillOpacity: 1,
                stroke: false
            };

        }

        function resetLayerStyle () {
            if (gjLayer) {
                gjLayer.setStyle(regionStyle);
            }
        }

        let jurisdictionLayer;
        let municipalityLayer;
        let regionLayer;

        async function fetchMunicipalityDetails (code) {
            const response = await fetch(`https://api.dataforsyningen.dk/kommuner/${code}?format=geojson`, {
                headers: {
                    'Authorization': 'Bearer 5a071872161bf7e93b45bbd11ef2bc0f'
                }
            });
            const data = await response.json();
            return data;
        }

        // Function to fetch and add the region to the map
        function addRegionsToMap () {
            if (map) {
                if (activeBaseLayer == 'Illustrative') {
                    gjLayer = L.geoJSON(regionsData, {
                        style: regionStyle,
                        onEachFeature: onEachFeature1
                    }).addTo(map);
                } else {
                    gjLayer = L.geoJSON(regionsData, {
                        style: regionStyle,
                        onEachFeature: onEachFeature1
                    })
                }
            }
        }

        function onRegionClick (e) {
            var layer = e.target;
            var select = document.getElementById('territory');
            let optionExists = false;
            const allOptions = select.choicesInstance._presetChoices;
            let value = '';
            for (let i = 0; i < allOptions.length; i++) {
                if (layer.feature.properties.navn.includes(allOptions[i].label.trim())) {
                    optionExists = true;
                    allOptions[i].selected = true;
                    value = allOptions[i].value
                    value = allOptions[i].value
                    select.selectedIndex = i;
                    break;
                }
            }

            if (optionExists && !itvangTheme) {
                updateMunicipality(value)
                select.choicesInstance.setChoiceByValue([value])
            }
            if (optionExists && itvangTheme) {
                select.choicesInstance.setChoiceByValue([value]);
                select.closest('.choices-wrapper').querySelector('.choices__inner input').style.opacity = '0';
                select.closest('.choices-wrapper').querySelector('.clear-value').classList.add('active');
                fetchPosts()
            }

            const values = select.choicesInstance.getValue();
            updateURLParams()
        }

        function onEachFeature1 (feature, layer) {
            layer.on({
                click: onRegionClick
            });
        }

        async function fetchJurisdictions (code) {
            const response = await fetch(`https://api.dataforsyningen.dk/retskredse/${code}?format=geojson`);
            const data = await response.json();
            return data;
        }

        async function fetchSpecificRegion (code) {
            const response = await fetch(`https://api.dataforsyningen.dk/regioner/${code}?format=geojson`);
            const data = await response.json();
            return data;
        }

        let newLayers = [];
        let newJurisdictionLayers = [];

        async function addSpecificRegionToMap (code) {
            if (map) {
                if (code !== '') {
                    if (!itvangTheme) {
                        removeJurisdictionLayer();
                    }
                    const regionData = await fetchSpecificRegion(code);
                    if (activeBaseLayer === 'Illustrative') {
                        regionLayer = L.geoJSON(regionData, {
                            style: municipalityStyle
                        }).addTo(map);
                        newLayers.push(regionLayer);
                    }

                } else {
                    removeJurisdictionLayer();
                }
            }
        }

        async function addJurisdictionsToMap (code) {
            console.log('jur')
            if (map) {
                if (code !== '') {
                    if (!itvangTheme) {
                        removeJurisdictionLayer();
                    }
                    const jurisdictionsData = await fetchJurisdictions(code);
                    if (activeBaseLayer === 'Illustrative') {
                        jurisdictionLayer = L.geoJSON(jurisdictionsData, {
                            style: jurisdictionStyle
                        }).addTo(map);
                        newJurisdictionLayers.push(jurisdictionLayer);
                    }

                } else {
                    removeJurisdictionLayer();
                }
            }
        }

        function removeJurisdictionLayer () {
            if (map) {
                if (jurisdictionLayer) {
                    if (newJurisdictionLayers) {
                        newJurisdictionLayers.forEach(layer => {
                            if (layer) {
                                map.removeLayer(layer); // Remove each layer from the map
                            }
                        });
                        newJurisdictionLayers = [];
                    } else {
                        map.removeLayer(jurisdictionLayer);
                    }
                    jurisdictionLayer = null;
                }
                if (municipalityLayer) {
                    map.removeLayer(municipalityLayer);
                    municipalityLayer = null;
                }
                if (regionLayer) {
                    if (newLayers) {
                        newLayers.forEach(layer => {
                            if (layer) {
                                map.removeLayer(layer); // Remove each layer from the map
                            }
                        });
                        newLayers = [];
                    } else {
                        map.removeLayer(regionLayer);
                    }
                    regionLayer = null;
                }
            }
        }

        async function addMunicipalityToMap (code) {
            if (map) {
                if (code !== '') {
                    removeJurisdictionLayer();
                    const municipalityData = await fetchMunicipalityDetails(code);
                    if (activeBaseLayer === 'Illustrative') {
                        municipalityLayer = L.geoJSON(municipalityData, {
                            style: municipalityStyle,
                        }).addTo(map);
                    }
                } else {
                    removeJurisdictionLayer();
                }
            }
        }

        addRegionsToMap();

        //search page list/map view toggle
        const toggleList = block.querySelectorAll('.btn-view-type');
        toggleList.forEach(function (btn) {
            btn.addEventListener('click', function () {
                const targetId = this.getAttribute('data-target');
                const targetElement = block.querySelector('#' + targetId);

                if (targetElement) {
                    block.querySelectorAll('.btn-view-type').forEach(function (toggle) {
                        toggle.classList.remove('active');
                        block.querySelector('.search-map-wrapper').classList.remove('view-type-map');
                        block.querySelector('.search-map-wrapper').classList.remove('view-type-list');
                    });

                    block.querySelectorAll('.target-element').forEach(function (content) {
                        content.classList.remove('active')
                    });
                    this.classList.add('active');
                    targetElement.classList.add('active');
                    block.querySelector('.search-map-wrapper').classList.add('view-type-' + targetId)
                    block.querySelector('.order-by-date-wrapper').classList.remove('hide')
                }

                if (targetElement.id == 'map') {
                    map.invalidateSize();
                    resetMap();
                    if (block.querySelector('.order-by-date-wrapper')) {
                        block.querySelector('.order-by-date-wrapper').classList.add('hide')
                    }
                }
            });
        });

        //update select municipality based on area selection
        function updateMunicipality (e) {
            const element = block.querySelectorAll('#territory')[1];
            const choicesInstance = element.choicesInstance;
            choicesInstance.containerOuter.element.classList.add('loading')
            let id;
            if (e.detail && e.detail.value) {
                id = e.detail.value;
            } else {
                id = e;
            }
            let params = {
                'action': 'update_municipality',
                'id': id
            };

            post(params).then(response => {
                choicesInstance.containerOuter.element.classList.remove('loading')
                if (response.success) {
                    if (response.data) {

                        const placeholderChoice = {
                            id: '',
                            name: 'Kommuner',
                            disabled: true,
                            selected: true,
                            placeholder: true
                        };

                        const combinedChoices = [placeholderChoice].concat(
                            response.data.map(item => ({
                                id: item.id,
                                name: item.name,
                                customProperties: {slug: item.slug}
                            }))
                        );

                        choicesInstance.removeActiveItems();
                        choicesInstance.clearStore();
                        choicesInstance.clearChoices();
                        choicesInstance.setChoices(combinedChoices, 'id', 'name', true);
                        choicesInstance.setChoiceByValue('');
                        updateURLParams()
                        if (!block.classList.contains('search-view') && !isMobile()) {
                            fetchPosts();
                        }
                    }
                }
            })
        }

        //get all selected values from filters form
        function getSelectedValues () {
            const form = block.querySelector('.search-filter-form');
            const selects = block.querySelectorAll('select');
            const checkboxes = form.querySelectorAll('input[type="checkbox"]:checked');
            const search = form.querySelectorAll('input[type="search"]');
            const selectedValues = {};
            const textInputs = form.querySelectorAll('input[type="text"]');

            selects.forEach(select => {
                if (select.classList.contains('choices')) {
                    const choicesInstance = select.choicesInstance;
                    if (choicesInstance) {
                        selectedValues[select.name] = choicesInstance.getValue(true).map(item => item.value);
                    }
                } else {
                    selectedValues[select.name] = Array.from(select.selectedOptions).map(option => option.value);
                }
            });

            checkboxes.forEach(checkbox => {
                const name = checkbox.name;
                const value = checkbox.value;

                if (!selectedValues[name]) {
                    selectedValues[name] = [];
                }
                selectedValues[name].push(value);
            });

            textInputs.forEach(input => {
                let name = input.name;
                let value = input.value;
                if (!selectedValues[name]) {
                    selectedValues[name] = [];
                }
                selectedValues[name].push(value);
            })
            search.forEach(s => {
                let name = s.name;
                let value = s.value;
                // selectedValues.s = search.value
                if (!selectedValues[name]) {
                    selectedValues[name] = [];
                }
                selectedValues[name].push(value);
            })

            return selectedValues;

        }

        const form = block.querySelector('.search-filter-form');
        block.querySelectorAll('select').forEach(select => {
            select.addEventListener('change', function (e) {
                const choicesInstance = select.choicesInstance;

                if (e.target.name === 'territory' && !itvangTheme) {
                    updateMunicipality(e);
                } else if (e.target.name === 'sort-by') {
                    if (e.target.choicesInstance) {
                        // console.log(e.target)
                        sortPosts('asc', e.target.value);
                        // updateURLParams();
                    }
                } else {
                    if (!block.classList.contains('search-view') && !isMobile()) {
                        fetchPosts();
                        // updateURLParams();
                    }
                }
                if (e.target.name !== 'territory' && !itvangTheme) {
                    updateURLParams()
                }

            })
        });
        // Add event listener for checkboxes
        form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
            checkbox.addEventListener('change', updateURLParams);
            if (!block.classList.contains('search-view') && !isMobile()) {
                checkbox.addEventListener('change', fetchPosts);
            }
        });

        if (form.querySelectorAll('.filter-block.toggle-dropdown')) {
            form.querySelectorAll('.filter-block.toggle-dropdown').forEach(block => {
                if (block) {
                    let counter = 0;
                    block.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                        if (checkbox) {
                            checkbox.addEventListener('change', function () {
                                const checkedCheckboxes = block.querySelectorAll('input[type="checkbox"]:checked');
                                const count = checkedCheckboxes.length;

                                if (block.querySelector('.count-active-filters span')) {
                                    block.querySelector('.count-active-filters span').innerHTML = count;
                                    // Add or remove the 'active' class based on the count
                                    if (count >= 1) {
                                        block.querySelector('.count-active-filters').classList.add('active');
                                    } else {
                                        block.querySelector('.count-active-filters').classList.remove('active');
                                    }
                                }

                            });
                        }
                    })

                    block.querySelectorAll('input[type="text"], input[type="search"]').forEach(textInput => {
                        textInput.addEventListener('input', function () {
                            let activeFiltersCount = 0;

                            if (textInput.type == 'text') {
                                let validRangeCount = 0;
                                const inputRangeContainers = Array.from(block.querySelectorAll('.input-range'));

                                inputRangeContainers.forEach(range => {
                                    const inputs = Array.from(range.querySelectorAll('input[type="text"]'));

                                    if (inputs.some(input => input.value.trim() !== '')) {
                                        validRangeCount++;
                                    }
                                });

                                // Count the calendar range if it has a value
                                const calendarRanges = block.querySelector('.calendar-range-picker');
                                if (calendarRanges && calendarRanges.querySelector('input').value.trim() !== '') {
                                    validRangeCount++;
                                }

                                // Add the valid range count to the total activeFiltersCount
                                activeFiltersCount += validRangeCount;
                            }

                            if (textInput.type === 'search') {
                                if (textInput.value !== '') {
                                    activeFiltersCount += 1;
                                }
                                let selectedOptionsCount = Array.from(block.querySelectorAll('select'))
                                    .filter(select => select.value.trim() !== '' && select.value !== null)
                                    .length;

                                if (selectedOptionsCount > 0) {
                                    activeFiltersCount += 1;
                                }
                            }
                            if (block.querySelector('.count-active-filters span')) {
                                block.querySelector('.count-active-filters span').innerHTML = activeFiltersCount;

                                if (activeFiltersCount >= 1) {
                                    block.querySelector('.count-active-filters').classList.add('active');
                                } else {
                                    block.querySelector('.count-active-filters').classList.remove('active');
                                }

                            }
                        });
                    });

                    block.querySelectorAll('select').forEach(selectElement => {
                        selectElement.addEventListener('change', function () {
                            let selectedOptionsCount = Array.from(form.querySelectorAll('select'))
                                .filter(select => select.value.trim() !== '' && select.value !== null)
                                .length;
                            let search = block.querySelector('input[name="search-post-code"]');
                            let count = 0;
                            if (search.value !== '') {
                                count = 1;
                            }
                            if (selectedOptionsCount >= 1) {
                                count += 1
                            }

                            block.querySelector('.count-active-filters span').innerHTML = count;

                            if (selectedOptionsCount >= 1) {
                                block.querySelector('.count-active-filters').classList.add('active');
                            } else {
                                block.querySelector('.count-active-filters').classList.remove('active');
                            }
                        });
                    })
                }
            })
        }

        const textInputs = form.querySelectorAll('input[type="text"]');

        if (textInputs) {
            textInputs.forEach(textInput => {
                textInput.addEventListener('input', function () {
                    updateURLParams();
                })
            })
        }

        //Add event listener for search input
        const searchInputs = form.querySelectorAll('input[type="search"]');
        searchInputs.forEach(searchInput => {
            searchInput.addEventListener('input', function () {
                updateURLParams();
                const searchValue = this.value.trim();
                if (searchValue.length > 1 || searchValue.length === 0) {
                    if (!block.classList.contains('search-view') && !isMobile()) {
                        fetchPosts()
                    }
                }
                let searchPostalCode;
                let searchAddress;
                if ((isMobile() || block.classList.contains('search-view')) && searchValue.length > 2) {
                    if (searchInput.name === 'search-post-code') {
                        searchPostalCode = searchInput.value
                    } else {
                        searchAddress = searchInput.value
                    }
                    const params = {
                        action: 'searchResults',
                        s: searchAddress,
                        postal_code: searchPostalCode,
                        type: searchInput.name
                    };

                    post(params).then(response => {
                        block.querySelector('.search-results').innerHTML = '';
                        if (response.data.response && response.data.response.length > 0) {
                            block.querySelector('.search-results').classList.remove('d-none');
                            response.data.response.forEach(title => {
                                block.querySelector('.search-results').innerHTML += `<p>${title.title}</p>`;
                            })
                            if (block.querySelector('.search-results').closest('.search-input-wrapper').querySelector('input[type="search"]') === document.activeElement) {
                                handleSearchResults(block.querySelector('.search-results'))
                            } else {
                                block.querySelector('.search-results').classList.add('d-none');
                            }
                        }
                    })
                }
            })
        })

        function handleSearchResults (searchResults) {

            if (!searchResults.classList.contains('d-none')) {
                searchResults.querySelectorAll('p').forEach(title => {
                    title.addEventListener('click', function () {
                        let modifiedString = title.textContent.replace(/–/g, '-');
                        const searchInput = searchResults.closest('.search-input-wrapper').querySelector('input[type="search"]');
                        searchInput.value = modifiedString;

                        updateURLParams();
                        searchResults.innerHTML = '';
                        searchResults.classList.add('d-none');
                        if (!isMobile()) {
                            fetchPosts()
                        }
                    });
                });
            }
        }

        let isFirstAjaxCall = true;

        //fetch properties
        function fetchPosts () {
            let filterWrapper = block.querySelector('.filter-wrapper');
            let filterButtonWrapper = block.querySelector('.filter-button-wrapper');
            filterWrapper.classList.add('loading');
            const selectedValues = getSelectedValues();
            let list = block.querySelector('.list-container .list-wrapper');
            currentRegionCode = '';
            teritorryCode = '';
            municipalityCode = '';
            const params = {
                action: 'get_all_posts_ajax',
                ...selectedValues
            };
            post(params).then(response => {
                filterWrapper.classList.remove('loading');

                if (filterWrapper.classList.contains('active') && block.querySelector('.search-mobile-header').classList.contains('active')) {
                    filterWrapper.classList.remove('active');
                    block.querySelector('.search-mobile-header').classList.remove('active');
                }

                clearMarkers();
                resetLayerStyle();
                if (response.success) {

                    if (block.querySelector('.no-results')) {
                        block.querySelector('.no-results').classList.remove('active');
                    }

                    if (response.data.response) {
                        //if homepage
                        if (!block.classList.contains('search-view')) {
                            let updatedLink = block.querySelector('.main-button.filter-button').getAttribute('href');
                            const urlAfterDomain = '/soeg' + window.location.pathname;
                            block.querySelector('.main-button.filter-button').setAttribute('href', urlAfterDomain);
                            if (!itvangTheme && isFirstAjaxCall) {
                                console.log('theme')
                                filterButtonWrapper.querySelector('span').innerHTML += ' (' + response.data.response.length + ')';
                            }
                            if (!isFirstAjaxCall) {
                                //filter button update
                                filterButtonWrapper.querySelector('span').innerHTML = filterButtonWrapper.getAttribute('data-button-title');
                                filterButtonWrapper.querySelector('span').innerHTML += ' (' + response.data.response.length + ')';
                            }
                        } else {
                            block.querySelector('.map-list-wrapper .title').innerHTML = response.data.title;
                            document.title = response.data.meta_title;
                            let metaDescription = document.querySelector('meta[name="description"]');
                            if (metaDescription) {
                                metaDescription.setAttribute('content', response.data.meta_description);
                            } else {
                                metaDescription = document.createElement('meta');
                                metaDescription.name = "description";
                                metaDescription.content = response.data.meta_description;
                                document.head.appendChild(metaDescription);
                            }

                            if (isFirstAjaxCall && !itvangTheme) {
                                // updateURLParams()
                                if (!selectedValues.municipality) {
                                    //update municipality select in tvangsauktioner theme
                                    updateMunicipality(selectedValues.territory[0]);
                                }
                                updateURLParams()
                            }
                        }

                        response.data.response.forEach(post => {
                            let tag = '';
                            let name = '';
                            let type = '';
                            let tagColor = '';
                            if (post.lat !== null && post.long !== null) {
                                if (post.type) {
                                    tagColor = post.tag;
                                    type = post.type[0].slug;
                                    tag = 'tag ' + tagColor;
                                    name = post.type[0].name.substring(0, 1);
                                }
                                let myIcon;
                                if (itvangTheme) {
                                    myIcon = L.divIcon({
                                        className: 'map-popup-icon ' + tag,
                                        html: `<div class="pin-icon-wrapper">
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1695_8496)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.35449 26.7085C8.35714 21.0335 10.6389 15.5918 14.6981 11.5802C18.7572 7.56863 24.2613 5.31553 30.0001 5.31641C41.9517 5.31641 51.6456 14.8961 51.6456 26.7085C51.6456 35.5143 47.19 42.4924 42.4146 47.2081C40.3037 49.3047 37.9379 51.1343 35.3715 52.6549C34.2992 53.2806 33.2968 53.7746 32.4244 54.1105C31.6018 54.4332 30.7393 54.6835 30.0001 54.6835C29.2608 54.6835 28.3983 54.4332 27.5758 54.1105C26.5572 53.7006 25.5719 53.214 24.6286 52.6549C22.0623 51.1342 19.6965 49.3046 17.5855 47.2081C12.8101 42.4924 8.35449 35.5143 8.35449 26.7085ZM30.0001 18.479C27.7921 18.479 25.6745 19.3464 24.1132 20.8903C22.552 22.4343 21.6748 24.5283 21.6748 26.7118C21.6748 28.8952 22.552 30.9893 24.1132 32.5332C25.6745 34.0772 27.7921 34.9446 30.0001 34.9446C32.208 34.9446 34.3256 34.0772 35.8869 32.5332C37.4482 30.9893 38.3253 28.8952 38.3253 26.7118C38.3253 24.5283 37.4482 22.4343 35.8869 20.8903C34.3256 19.3464 32.208 18.479 30.0001 18.479Z" fill="#2196F3"/>
<g filter="url(#filter0_dd_1695_8496)">
<rect x="16" y="13" width="28" height="28" rx="14" fill="white"/>
<rect x="17" y="14" width="26" height="26" rx="13" stroke="white" stroke-width="2"/>
</g>
</g>
<defs>
<filter id="filter0_dd_1695_8496" x="-32.6076" y="-3.20253" width="125.215" height="125.215" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="32.4051"/>
<feGaussianBlur stdDeviation="24.3038"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1695_8496"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="6.07595"/>
<feGaussianBlur stdDeviation="6.07595"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_1695_8496" result="effect2_dropShadow_1695_8496"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1695_8496" result="shape"/>
</filter>
<clipPath id="clip0_1695_8496">
<rect width="60" height="60" fill="white"/>
</clipPath>
</defs>
</svg></div><span class="small-circle">${name}</span>`,
                                        popupAnchor: [0, -30],
                                    });
                                } else {
                                    myIcon = L.divIcon({
                                        className: 'map-popup-icon ' + tag,
                                        html: `<span class="small-circle">${name}</span>`,
                                        popupAnchor: [13, -13]
                                    });
                                }
                                let marker;
                                if (post.lat && post.lng) {
                                    marker = L.marker([post.lat, post.lng], {icon: myIcon});
                                }

                                let popupContentString = `
                        

                            <a href="${post.property_link}" class="popup-wrapper property-popup-wrapper property-card status-${post.status}">
                                <div class="image-tag-wrapper">`
                                if (post.type) {
                                    popupContentString += `<span class="tag ${post.tag}">${post.type[0].name}</span>`;
                                }
                                if (post.content.image) {
                                    popupContentString += `<img class="no-lazyload" src="${post.content.image}" alt="${post.title}">`;
                                } else {
                                    popupContentString += `
                               <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
<path d="M35.625 26.25C36.7375 26.25 37.8251 25.9201 38.7501 25.302C39.6751 24.6839 40.3961 23.8054 40.8218 22.7776C41.2476 21.7498 41.359 20.6188 41.1419 19.5276C40.9249 18.4365 40.3891 17.4342 39.6025 16.6475C38.8158 15.8609 37.8135 15.3251 36.7224 15.1081C35.6312 14.891 34.5002 15.0024 33.4724 15.4282C32.4446 15.8539 31.5661 16.5749 30.948 17.4999C30.3299 18.4249 30 19.5125 30 20.625C30 22.1168 30.5926 23.5476 31.6475 24.6025C32.7024 25.6574 34.1332 26.25 35.625 26.25ZM35.625 18.75C35.9958 18.75 36.3584 18.86 36.6667 19.066C36.975 19.272 37.2154 19.5649 37.3573 19.9075C37.4992 20.2501 37.5363 20.6271 37.464 20.9908C37.3916 21.3545 37.2131 21.6886 36.9508 21.9508C36.6886 22.213 36.3545 22.3916 35.9908 22.464C35.6271 22.5363 35.2501 22.4992 34.9075 22.3573C34.5649 22.2154 34.272 21.975 34.066 21.6667C33.86 21.3584 33.75 20.9958 33.75 20.625C33.75 20.1277 33.9475 19.6508 34.2992 19.2992C34.6508 18.9475 35.1277 18.75 35.625 18.75Z" fill="#AAAEBD"/>
<path d="M48.75 7.5H11.25C10.2554 7.5 9.30161 7.89509 8.59835 8.59835C7.89509 9.30161 7.5 10.2554 7.5 11.25V48.75C7.5 49.7446 7.89509 50.6984 8.59835 51.4016C9.30161 52.1049 10.2554 52.5 11.25 52.5H48.75C49.7446 52.5 50.6984 52.1049 51.4016 51.4016C52.1049 50.6984 52.5 49.7446 52.5 48.75V11.25C52.5 10.2554 52.1049 9.30161 51.4016 8.59835C50.6984 7.89509 49.7446 7.5 48.75 7.5ZM48.75 48.75H11.25V37.5L20.625 28.125L31.1063 38.6063C31.8089 39.3047 32.7593 39.6967 33.75 39.6967C34.7407 39.6967 35.6911 39.3047 36.3937 38.6063L39.375 35.625L48.75 45V48.75ZM48.75 39.6937L42.0187 32.9625C41.3161 32.2641 40.3657 31.872 39.375 31.872C38.3843 31.872 37.4339 32.2641 36.7313 32.9625L33.75 35.9438L23.2687 25.4625C22.5661 24.7641 21.6157 24.372 20.625 24.372C19.6343 24.372 18.6839 24.7641 17.9813 25.4625L11.25 32.1938V11.25H48.75V39.6937Z" fill="#AAAEBD"/>
</svg>`;
                                }
                                popupContentString += `  </div>
                                <h3>${post.title}</h3>
                                <div class="popup-content-wrapper">`
                                if (post.content.bid) {
                                    popupContentString += `<p>${post.content.bid_title}</p>
                                    <h2>${post.content.bid}</h2>`
                                }
                                popupContentString += `<div class="property-characteristics">`;
                                if (post.content.auction !== '') {
                                    popupContentString += `
                                        <div class="single-characteristic">
                                            <span>${post.content.auction_title}</span>
                                            <span>${post.content.auction}</span>
                                        </div>
                                    `;
                                }

                                if (post.content.start_date !== '') {
                                    popupContentString += `
                                     <div class="single-characteristic">
                                        <span>${post.content.start_date_title}</span>
                                        <span>${post.content.start_date}</span>
                                    </div>
                                    `;
                                }

                                if (post.content.value !== '') {
                                    popupContentString += `
                                         <div class="single-characteristic">
                                            <span>${post.content.value_title}</span>
                                            <span>${post.content.value}</span>
                                        </div>
                                    `;
                                }
                                popupContentString += `<div class="border-top-wrapper">`;

                                if (post.content.residence !== "") {
                                    popupContentString += `
                                             <div class="single-characteristic residence-characteristic">
                                                <span>${post.content.residence_title}</span>
                                                <span>${post.content.residence}</span>
                                            </div>
                                            `;
                                }

                                if (post.content.profession !== "") {
                                    popupContentString += `
                                            <div class="single-characteristic profession-characteristic">
                                                <span>${post.content.profession_title}</span>
                                                <span>${post.content.profession}</span>
                                            </div>
                                            `;
                                }

                                if (post.content.reason !== "") {
                                    popupContentString += `
                                             <div class="single-characteristic reason-characteristic">
                                                <span>${post.content.reason_title}</span>
                                                <span>${post.content.reason}</span>
                                            </div>
                                              `;
                                }
                                popupContentString += `</div>`;
                                popupContentString += `
                                        </div>
                                    </div>
                                    <div class="main-button-wrapper"><div class="main-button"><span>${post.content.button_title}</span></div></div>
                                </a>
                            `;
                                if (marker) {
                                    let popup = marker.bindPopup(popupContentString, {
                                        keepInView: true,
                                        closeOnEscapeKey: true,
                                        // direction: center,
                                        autoClose: false,
                                        autoPan: isMobile() ? false : true,
                                    });


                                    marker.on('click', function (e) {
                                        block.querySelector('.leaflet-bottom.leaflet-right').classList.add('under');
                                        block.querySelector('.leaflet-top.leaflet-right').classList.add('under');
                                        if(block.querySelector('.new-today-auctions-wrap.mobile-hide')) {
                                            block.querySelector('.new-today-auctions-wrap.mobile-hide').style.display = 'none';
                                        }

                                        if (block.querySelector('.results-list-map-wrapper')) {
                                            block.querySelector('.results-list-map-wrapper').classList.add('under');
                                        }
                                        if (isMobile()) {
                                            let popupContent = this.getPopup();
                                            popupContent.setLatLng(map.getCenter()).setContent(popupContent.getContent());
                                            let width = window.innerWidth;
                                            popupContent._container.style.width = width + 'px';
                                            popupContent._contentNode.style.width = width + 'px';
                                            popupContent._container.style.left += '10';
                                            popupContent.update();
                                        }
                                        if (!marker.getPopup().isOpen()) {
                                            if(block.querySelector('.new-today-auctions-wrap.mobile-hide')) {
                                                block.querySelector('.new-today-auctions-wrap.mobile-hide').style.display = 'flex';
                                            }
                                            block.querySelector('.leaflet-top.leaflet-right').classList.remove('under');
                                            block.querySelector('.leaflet-bottom.leaflet-right').classList.remove('under');
                                            if (block.querySelector('.results-list-map-wrapper')) {
                                                block.querySelector('.results-list-map-wrapper').classList.remove('under');
                                            }
                                        }

                                        if (itvangTheme && !isMobile()) {
                                            let markerLatLng = e.latlng;
                                            let markerPoint = map.latLngToContainerPoint(markerLatLng);
                                            let headerHeight = 60;
                                            let noPopupZoneHeight = 70;
                                            let filterElement = block.querySelector('.filter-wrapper');
                                            let filterWidth = filterElement ? filterElement.offsetWidth : 0;
                                            let filterOffsetLeft = filterElement ? filterElement.getBoundingClientRect().left : 0;
                                            let popup = this.getPopup().getElement();
                                            let popupWidth = popup.offsetWidth;
                                            this.openPopup();

                                            let popupHeight = popup.offsetHeight;
                                            let adjustY = 0;
                                            let intendedX = markerPoint.x - popupWidth / 2;
                                            let adjustX = 0;

                                            if (intendedX < filterOffsetLeft + filterWidth) {
                                                adjustX = filterOffsetLeft + filterWidth - intendedX;
                                            }
                                            if (markerPoint.y - popupHeight < (headerHeight + noPopupZoneHeight)) {
                                                adjustY = (headerHeight + noPopupZoneHeight) - (markerPoint.y - popupHeight) + 10;
                                            }
                                            map.panBy([-adjustX, -adjustY], {
                                                animate: true
                                            });
                                        }

                                    });

                                    marker.getPopup().on('remove', function () {
                                        if(block.querySelector('.new-today-auctions-wrap.mobile-hide')) {
                                            block.querySelector('.new-today-auctions-wrap.mobile-hide').style.display = 'flex';
                                        }
                                        block.querySelector('.leaflet-top.leaflet-right').classList.remove('under');
                                        block.querySelector('.leaflet-bottom.leaflet-right').classList.remove('under');
                                        if (block.querySelector('.results-list-map-wrapper')) {
                                            block.querySelector('.results-list-map-wrapper').classList.remove('under');
                                        }
                                    });

                                    markers.push(marker);
                                }
                            }
                        });
                        block.querySelector('.search-results').innerHTML = '';
                        //search input results
                        if (response.data.is_search && response.data.response.length > 0) {
                            block.querySelector('.search-results').classList.remove('d-none');
                            response.data.response.forEach(title => {
                                block.querySelector('.search-results').innerHTML += `<p>${title.title}</p>`;
                            })
                            if (block.querySelector('.search-results').closest('.search-input-wrapper').querySelector('input[type="search"]') == document.activeElement) {
                                handleSearchResults(block.querySelector('.search-results'))
                            } else {
                                block.querySelector('.search-results').classList.add('d-none');
                            }
                        }

                        if (!isFirstAjaxCall && markers.length == 0) {
                            resetMap();
                        }

                        markerCluster = L.markerClusterGroup({
                            showCoverageOnHover: false,
                            removeOutsideVisibleBounds: true,
                            maxClusterRadius: function (mapZoom) {
                                if (mapZoom > 9) {
                                    return 50;
                                } else {
                                    return 80;
                                }
                            },
                        });


                        markerCluster.addLayers(markers);
                        map.addLayer(markerCluster);

                        function fitMapToBoundsWithFilterAndHeader (cluster) {
                            const map = cluster._map;
                            const filterWidth = 500;
                            const containerWidth = 1440;
                            const screenWidth = window.innerWidth;
                            const containerPadding = (screenWidth - containerWidth) / 2;
                            const headerHeight = 70;
                            let leftPadding;

                            if (screenWidth > containerWidth) {
                                leftPadding = containerPadding + filterWidth;
                            } else {
                                leftPadding = filterWidth;
                            }

                            const markers = cluster.getAllChildMarkers();
                            const bounds = L.latLngBounds(markers.map(m => m.getLatLng()));

                            const padding = {
                                paddingTopLeft: [leftPadding + 20, headerHeight + 20],
                                paddingBottomRight: [20, 20]
                            };

                            map.fitBounds(bounds, padding);
                        }

                        if (itvangTheme) {
                            if (!isMobile()) {
                                markerCluster.on('clusterclick', function (e) {
                                    fitMapToBoundsWithFilterAndHeader(e.layer);
                                });
                            }
                        }

                        if (!isFirstAjaxCall && markers.length !== 0) {
                            const group = new L.featureGroup(markers);
                            const allMarkersBounds = group.getBounds();
                            if (!itvangTheme) {
                                map.fitBounds(allMarkersBounds, {
                                    padding: [50, 50],
                                    maxZoom: 9
                                });
                            } else {
                                if (!isMobile()) {
                                    map.setView(center, initialZoom)
                                }

                            }
                        }

                        if (isFirstAjaxCall) {
                            isFirstAjaxCall = false;
                        }

                        if (block.querySelector('.total-new-wrapper .auctions-count .number')) {
                            block.querySelector('.total-new-wrapper .auctions-count .number').innerHTML = response.data.response.length
                        }
                        if (block.querySelector('.total-new-wrapper .new-auctions-count .number')) {
                            block.querySelector('.total-new-wrapper .new-auctions-count .number').innerHTML = response.data.new_properties
                        }
                        if (block.querySelector('.counter-wrapper')) {
                            block.querySelector('.counter-wrapper span').innerHTML = response.data.response.length
                        }
                        if (block.querySelector('.results-counter')) {
                            block.querySelector('.results-counter').innerHTML = response.data.response.length
                        }
                        if (block.querySelector('form .search-results-count')) {
                            block.querySelector('.search-results-count').innerHTML = '(' + response.data.response.length + ')';
                        }

                    }


                    if (response.data.municipality_code != '') {
                        addMunicipalityToMap(response.data.municipality_code);
                        addJurisdictionsToMap('');
                        municipalityCode = response.data.municipality_code;
                    }
                    //if just jurisdiction code selected
                    if (response.data.teritorry_code !== '' && response.data.municipality_code == '' && response.data.region_code == '') {
                        if (itvangTheme) {
                            if (response.data.teritorry_code) {
                                response.data.teritorry_code.forEach(code => {
                                    addJurisdictionsToMap(code);
                                    teritorryCode = code;
                                });
                            }
                        } else {
                            addJurisdictionsToMap(response.data.teritorry_code);
                            teritorryCode = response.data.teritorry_code;
                        }
                    }
                    //if region code selected
                    if (response.data.region_code !== '' && response.data.municipality_code == '' && response.data.teritorry_code == '') {

                        if (itvangTheme) {
                            if (response.data.region_code) {
                                response.data.region_code.forEach(code => {
                                    addSpecificRegionToMap(code);
                                    currentRegionCode = code;
                                });
                            }
                        } else {
                            addSpecificRegionToMap(response.data.region_code);
                            currentRegionCode = response.data.region_code;
                        }
                    }

                    if (itvangTheme && response.data.region_code !== '' && response.data.teritorry_code != '') {
                        if (response.data.teritorry_code) {
                            response.data.teritorry_code.forEach(code => {
                                addJurisdictionsToMap(code);
                                teritorryCode = code;
                            });
                        }
                        if (response.data.region_code) {
                            response.data.region_code.forEach(code => {
                                addSpecificRegionToMap(code);
                                currentRegionCode = code;
                            });
                        }
                    }

                    if (response.data.cards && list) {
                        list.innerHTML = response.data.cards;
                        if (response.data.response.length == 0) {
                            block.querySelector('.no-results').classList.add('active');
                        }
                    }
                }
                let pdfs = document.querySelectorAll('.download-pdf-wrap');
                console.log(pdfs)
                if (pdfs) {
                    pdfs.forEach(pdf => {
                        let pdfUrl = pdf.getAttribute('data-pdf')
                        let fileName = pdf.dataset.filename
                        pdf.addEventListener('click', function (event) {
                            event.preventDefault();
                            event.stopPropagation()

                            window.location.href = pdf.getAttribute('data-pdf');
                        })
                    })
                }
            }).catch(error => {
                console.error('Error fetching posts:', error);
            });
        }

        if (block.querySelector('.search-results').closest('.search-input-wrapper').querySelector('input[type="search"]') == document.activeElement) {
            handleSearchResults(block.querySelector('.search-results'))
        }

        const postsContainer = block.querySelector('.list-wrapper');

        //order properties list by date ASC/DESC
        function sortPosts (order, sortby) {
            const posts = Array.from(postsContainer.getElementsByClassName('property-card'));

            posts.sort((a, b) => {
                let valueA, valueB;

                switch (sortby) {
                    case 'newest-to-oldest':
                        valueA = new Date(a.getAttribute('data-date'));
                        valueB = new Date(b.getAttribute('data-date'));
                        order = 'desc';
                        break;
                    case 'zipcode':
                        valueA = parseInt(a.getAttribute('data-zip'));
                        valueB = parseInt(b.getAttribute('data-zip'));
                        break;
                    case 'auction-date':
                        valueA = new Date(a.getAttribute('data-auction'));
                        valueB = new Date(b.getAttribute('data-auction'));
                        break;
                    default:
                        throw new Error('Invalid sortBy parameter. Must be "date", "zip", or "number".');
                }

                return order === 'asc' ? valueA - valueB : valueB - valueA;
            });

            posts.forEach(post => postsContainer.appendChild(post));
        }

        //clear filters,URL  and reset properties
        const clearButtons = block.querySelectorAll('.clear-filter-btn');
        clearButtons.forEach(clearBtn => {
            initializeClearFilterButton(clearBtn, form);
        });

        function handleFormReset () {
            event.preventDefault()
            form.querySelectorAll('input[type="search"], textarea,input[type="text"]').forEach(field => field.value = '');
            form.querySelectorAll('input[type="checkbox"]').forEach(field => field.checked = false);
            form.querySelectorAll('select').forEach(select => {
                const selectedOption = select.choicesInstance
                selectedOption.removeActiveItems();
            })

        }

        function initializeClearFilterButton (clearBtn, form) {
            clearBtn.addEventListener('click', function (event) {

                event.preventDefault();
                handleFormReset()
                removeJurisdictionLayer();
                resetMap();

                if (flatPickerCalendar) {
                    flatPickerCalendar.clear();
                }

                // let baseUrl = window.location.href.split('?')[0];
                // history.pushState(null, null, baseUrl);
                updateURLParams()

                form.querySelectorAll('select').forEach(select => {
                    const choicesInstance = select.choicesInstance;
                    if (!itvangTheme) {
                        if (choicesInstance) {
                            choicesInstance.removeActiveItems();
                            choicesInstance.setChoiceByValue('');
                            choicesInstance.config.placeholderValue = 'Landsdele og retskredse';
                        }
                    }
                    if (!itvangTheme) {
                        updateMunicipality('')
                    }
                    if (itvangTheme) {
                        form.querySelector('.choices-wrapper .choices__inner input').style.opacity = '1';
                        form.querySelector('.choices-wrapper .clear-values').classList.remove('active');

                        if (choicesInstance) {
                            const selectedOption = select.choicesInstance
                            selectedOption.removeActiveItems();
                            form.querySelector('.choices-wrapper .clear-value').classList.remove('active');

                        }
                        form.querySelectorAll('.count-active-filters').forEach(filter => {
                            if (filter.classList.contains('active')) {
                                filter.classList.remove('active')
                            }
                        })
                        form.querySelectorAll('.clear-values').forEach(btn => {
                            if (btn.classList.contains('active')) {
                                btn.classList.remove('active')
                            }
                        })
                    }
                });

                form.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
                    if (checkbox.checked) {
                        checkbox.checked = false;
                    }
                });

                let searchInputs = form.querySelectorAll('input[type="search"]');

                searchInputs.forEach(searchInput => {
                    if (searchInput.value) {
                        searchInput.value = "";
                    }
                })


                if (!isMobile()) {
                    fetchPosts();
                }
            });
        }

        function updateURLParams () {
            const form = block.querySelector('form');
            const formData = new FormData(form);
            const searchParams = new URLSearchParams();
            const checkboxGroups = [];
            const checkboxGroups1 = {};

            for (const pair of formData.entries()) {
                const name = pair[0];
                const value = pair[1].trim();

                if (form.querySelector(`input[name="${name}"][type="checkbox"]`)) {

                    if (name == 'category[]') {
                        if (value) {
                            checkboxGroups.push(value);
                        }
                    } else {

                        if (!checkboxGroups1[name]) {
                            checkboxGroups1[name] = [];
                        }
                        if (value) {
                            checkboxGroups1[name].push(value);
                        }
                    }
                }
            }
            let currentPath = window.location.pathname;
            let newPathSegments = currentPath.split('/').filter(Boolean);
            const isSogPage = newPathSegments[0] === 'soeg';
            if (isSogPage) {
                newPathSegments = newPathSegments.slice(1); // Temporarily remove sog
            }

            form.querySelectorAll('select').forEach(select => {
                const selectedOption = select.choicesInstance.getValue();

                if (itvangTheme && select.name === 'territory') {
                    const choicesInstance = select.choicesInstance;
                    if (choicesInstance) {
                        const selectedOptions = choicesInstance.getValue();
                        const paramKey = select.getAttribute('name') || select.getAttribute('id');
                        let optionTexts = [];
                        selectedOptions.forEach(selectedOption => {
                            let optionSlug = (selectedOption.customProperties && selectedOption.customProperties.slug) ? selectedOption.customProperties.slug : null;
                            const optionText = selectedOption.label.trim();
                            const optionValue = selectedOption.value;
                            if (optionText && optionValue !== "") {
                                optionTexts.push(optionSlug);
                            }
                        });

                        let selectedOptionsString = optionTexts.length > 0 ? optionTexts.join('+') : null;
                        if (selectedOptionsString) {
                            newPathSegments[0] = selectedOptionsString;
                        } else {
                            newPathSegments.shift();
                        }

                    }
                } else {
                    if (selectedOption && selectedOption.label) {

                        let optionSlug = (selectedOption.customProperties && selectedOption.customProperties.slug && selectedOption.active !== false) ? selectedOption.customProperties.slug : null;

                        const selectName = select.getAttribute('name');
                        if (selectName === 'territory') {
                            if (optionSlug) {
                                newPathSegments[0] = optionSlug;
                            } else {
                                newPathSegments.shift();
                            }
                        }

                        if (selectName === 'municipality') {
                            if (optionSlug) {

                                if (newPathSegments.length < 1 || !newPathSegments[0]) {
                                    newPathSegments[0] = optionSlug;
                                } else {
                                    newPathSegments[1] = optionSlug;
                                }
                            } else if (newPathSegments.length > 1) {
                                newPathSegments.splice(1, 1);
                            }
                        }

                    }
                }

            });

            let checkboxQueryString = checkboxGroups.length > 0 ? checkboxGroups.join('+') : null;
            if (!itvangTheme) {
                if (checkboxQueryString) {
                    if (newPathSegments.length === 3) {
                        newPathSegments[2] = checkboxQueryString;
                    } else if (newPathSegments.length < 3) {
                        if (!newPathSegments.includes(checkboxQueryString)) {
                            newPathSegments.push(checkboxQueryString);
                        }
                    }
                } else if (newPathSegments.length === 3) {
                    newPathSegments.pop();
                }
            } else {
                if (checkboxQueryString) {
                    if (newPathSegments.length === 2) {
                        newPathSegments[1] = checkboxQueryString;
                    } else if (newPathSegments.length < 2) {
                        if (!newPathSegments.includes(checkboxQueryString)) {
                            newPathSegments.push(checkboxQueryString);
                        }
                    }
                } else if (newPathSegments.length === 2) {
                    newPathSegments.pop();
                }
            }

            if (isSogPage) {
                newPathSegments.unshift('soeg');
            }

            for (const [name, values] of Object.entries(checkboxGroups1)) {
                if (values.length > 0) {
                    searchParams.set(name, values.join(','));
                } else {
                    searchParams.delete(name);
                }
            }

            const searchInput = form.querySelectorAll('input[type="search"],input[type="text"]');

            searchInput.forEach(search => {
                const searchInputValue = search ? search.value.trim() : '';

                if (searchInputValue) {
                    searchParams.set(search.name, searchInputValue);
                } else {
                    searchParams.delete(search.name);
                }

                var newUrl = window.location.pathname + '?' + searchParams.toString();
                window.history.pushState({}, '', newUrl);

            })

            let newPath = `/${newPathSegments.join('/')}`;
            if (!newPath.endsWith('/')) {
                newPath += '/';
            }
            let currentSearchParams = window.location.search;
            let newUrl = `${window.location.origin}${newPath}${currentSearchParams}`;


            history.pushState(null, '', newUrl);

            // const urlParams = new URLSearchParams(window.location.search);
            const clearFiltersButton = block.querySelectorAll('.clear-filter-btn');
            if (block.querySelectorAll('.clear-filter-btn.active')) {
                block.querySelectorAll('.clear-filter-btn.active').forEach(filter => {
                    filter.classList.remove('active');
                })
            }
            let paramSize = 0;
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.size !== paramSize) {
                clearFiltersButton.forEach(filter => {
                    filter.classList.add('active');
                })
            }

            let currentUrl = window.location.href;
            let urlObj = new URL(currentUrl);

            let pathname1 = urlObj.pathname;

            if (pathname1.includes('/soeg/') || pathname1.includes('/soeg')) {
                let afterSog = pathname1.split('/soeg/')[1];
                if (afterSog && afterSog !== "") {
                    clearFiltersButton.forEach(filter => {
                        filter.classList.add('active');
                    })
                }
            } else {
                // Check if the URL has any other path segment after the domain
                let pathSegmentsHomepage = pathname1.split('/').filter(Boolean);
                if (pathSegmentsHomepage.length >= 1) {
                    clearFiltersButton.forEach(filter => {
                        filter.classList.add('active');
                    })
                }
            }
            //if homepage and mobile
            if (isMobile() && !block.classList.contains('search-view')) {
                let updatedLink = block.querySelector('.main-button.filter-button').getAttribute('href');
                const urlAfterDomain = '/soeg' + window.location.pathname;
                block.querySelector('.main-button.filter-button').setAttribute('href', urlAfterDomain);
            }

        }

        if (block.querySelector('a.filter-button')) {
            block.querySelector('a.filter-button').addEventListener('click', function (event) {
                event.preventDefault();

                const urlParams = new URLSearchParams(window.location.search);
                const existingHref = block.querySelector('a.filter-button').getAttribute('href');
                const params = {};

                for (const [key, value] of urlParams) {

                    if (params.hasOwnProperty(key)) {
                        if (!Array.isArray(params[key])) {
                            params[key] = [params[key]];
                        }
                        params[key].push(value);
                    } else {
                        params[key] = value;
                    }
                }

                let newHref = existingHref;

                if (Object.keys(params).length > 0) {
                    // Only add the query string if params is not empty
                    newHref += '?' + new URLSearchParams(params).toString();
                }
                window.open(newHref, '_self');
            })
        }

        if (block.querySelector('.search-filter-button')) {
            block.querySelector('.search-filter-button').addEventListener('click', function () {
                fetchPosts()
                body.classList.remove('fixed');
            })
        }
        //search mobile popup
        const searchMobile = block.querySelector('.search-mobile');
        const editFilters = document.querySelector('.edit-filters');
        const searchMobileHeader = block.querySelector('.search-mobile-header');
        const searchFilters = block.querySelector('.filter-wrapper');
        const filterWrapper = block.querySelector('.filters-popup-mobile');
        const body = document.querySelector('body');

        searchMobile.addEventListener('click', function () {
            searchFilters.classList.add('active');
            searchMobile.classList.add('hide');
            searchMobileHeader.classList.add('active');
            body.classList.add('fixed');
        })

        if (editFilters) {
            editFilters.addEventListener('click', function () {
                searchFilters.classList.add('active');
                searchMobile.classList.add('hide');
                searchMobileHeader.classList.add('active');
                body.classList.add('fixed');
            })
        }

        const closeMobileFilters = block.querySelector('.close-filters-btn');

        closeMobileFilters.addEventListener('click', function () {
            searchFilters.classList.remove('active');
            searchMobile.classList.remove('hide');
            searchMobileHeader.classList.remove('active');
            body.classList.remove('fixed');
        })

        function isMobile () {
            return window.matchMedia("(max-width: 1024px)").matches;
        }

        if (block.querySelectorAll('.toggle-dropdown')) {
            block.querySelectorAll('.toggle-dropdown').forEach(dropdown => {
                if (dropdown.querySelector('h3')) {
                    dropdown.querySelector('h3').addEventListener('click', function () {
                        if (dropdown.querySelector('.toggle-content').classList.contains('show')) {
                            dropdown.querySelector('.toggle-content').classList.remove('show');
                            dropdown.classList.remove('active');
                        } else {
                            dropdown.querySelector('.toggle-content').classList.add('show');
                            dropdown.classList.add('active');
                        }
                    })
                }
            })
        }

        if (itvangTheme) {

            let inputRanges = form.querySelectorAll('.input-range');
            let clearValueBtns = form.querySelectorAll('.clear-values');
            const searchInput = form.querySelector('.choices__input--cloned');

            inputRanges.forEach(range => {
                let inputs = range.querySelectorAll('input');
                inputs.forEach(input => {
                    input.addEventListener('input', function (evt) {
                        updateURLParams()
                        let currentValue = evt.target.value;
                        let numericValue = currentValue.replace(/[^0-9]/g, '');
                        if (numericValue !== '') {
                            evt.target.value = numericValue
                            input.closest('.advanced-filters-wrapper').querySelector('.clear-values').classList.add('active')
                        } else {
                            evt.target.value = '';
                            input.closest('.advanced-filters-wrapper').querySelector('.clear-values').classList.remove('active');

                        }
                        if (!isMobile() && !block.classList.contains('search-view')) {
                            fetchPosts()
                        }
                    })
                })
            })

            if (form.querySelector('.calendar-range-picker') && form.querySelector('.calendar-range-picker input').value != '') {
                form.querySelector('.calendar-range-picker .clear-values').classList.add('active');
            }

            if (searchInput) {
                searchInput.addEventListener('keypress', function (e) {
                    e.preventDefault();
                })
            }

            clearValueBtns.forEach(btn => {
                btn.addEventListener('click', function () {
                    let validRangeCount = 0;
                    const inputRangeContainers = Array.from(btn.closest('.filter-block').querySelectorAll('.input-range'));

                    inputRangeContainers.forEach(range => {
                        const inputs = Array.from(range.querySelectorAll('input[type="text"]'));

                        if (inputs.some(input => input.value.trim() !== '')) {
                            validRangeCount += 1;
                        }
                    });

                    let count = validRangeCount - 1;
                    if (form.querySelector('input[name="auction-date"]')) {
                        if (form.querySelector('input[name="auction-date"]').value != '') {
                            count += 1;
                        }
                    }

                    btn.closest('.filter-block').querySelector('.count-active-filters span').innerHTML = count.toString();

                    if (count >= 1) {
                        btn.closest('.filter-block').querySelector('.count-active-filters').classList.add('active');
                    } else {
                        btn.closest('.filter-block').querySelector('.count-active-filters').classList.remove('active');
                    }

                    // Clear all inputs within the advanced-filters-wrapper
                    let inputs = btn.closest('.advanced-filters-wrapper').querySelectorAll('input');
                    inputs.forEach(inp => {
                        inp.value = '';
                        if (inp.name === 'auction-date') {
                            flatPickerCalendar.clear();
                        }
                    });
                    btn.classList.remove('active');

                    // Fetch posts if not on mobile and not in search view
                    if (!isMobile() && !block.classList.contains('search-view')) {
                        fetchPosts();
                    }
                    updateURLParams();
                });
            });


            function updateRemoveAllButtonVisibility () {
                const removeAllButton = form.querySelector('.choices-wrapper .clear-value')

                form.querySelectorAll('select').forEach(select => {
                    const selectedOption = select.choicesInstance.getValue();
                    if (selectedOption.length > 0) {
                        removeAllButton.classList.add('active'); // Show the button
                        form.querySelector('.choices-wrapper .choices__inner input[type="search"]').style.opacity = '0';
                    } else {
                        removeAllButton.classList.remove('active'); // Hide the button
                        form.querySelector('.choices-wrapper .choices__inner input[type="search"]').style.opacity = '1';
                    }
                    updateURLParams();
                    const dropdownContainer = form.querySelector('.choices');

                    if (selectedOption.length > 1) {
                        const firstSelected = selectedOption[0].label;
                        const additionalCount = selectedOption.length - 1;
                        const additionalText = additionalCount === 1 ? 'anden' : 'andre';

                        dropdownContainer.querySelector('.choices__inner .choices__list').innerHTML = `<div class="choices__item">${firstSelected} +${additionalCount}  ${additionalText}</div>`;
                    }
                })

            }

            updateRemoveAllButtonVisibility();
            const element = form.querySelector('.choices-js');
            element.addEventListener('change', updateRemoveAllButtonVisibility);

            form.querySelector('.choices-wrapper .clear-value').addEventListener('click', function () {
                form.querySelectorAll('select').forEach(select => {
                    const selectedOption = select.choicesInstance
                    selectedOption.removeActiveItems();
                })
                if (form.querySelector('.choices-wrapper .clear-value').closest('.filter-block').querySelector('input[name="search-post-code"]').value != 0) {
                    form.querySelector('.choices-wrapper .clear-value').closest('.filter-block').querySelector('.count-active-filters span').innerHTML = 1;
                } else {
                    form.querySelector('.choices-wrapper .clear-value').closest('.filter-block').querySelector('.count-active-filters span').innerHTML = 0;
                    form.querySelector('.choices-wrapper .clear-value').closest('.filter-block').querySelector('.count-active-filters').classList.remove('active');
                }
                updateRemoveAllButtonVisibility();
                if (!isMobile() && !block.classList.contains('search-view')) {
                    fetchPosts()
                    removeJurisdictionLayer()
                }
            });

            let filterCounts = block.querySelectorAll('.filter-block.toggle-dropdown');
            filterCounts.forEach(filter => {
                let fields = filter.querySelectorAll('input, select');
                let fieldRange = filter.querySelectorAll('.input-range');
                let calendar = filter.querySelector('.calendar-range-picker');
                let counter = 0;
                if (calendar) {
                    if (calendar.querySelector('input').value !== '') {
                        counter++;
                    }
                }
                fieldRange.forEach(field => {
                    const inputRanges = field ? Array.from(field.querySelectorAll('input[type="text"]')) : [];
                    if (inputRanges.length === 2 && inputRanges.every(input => input.value.trim() !== '')) {
                        counter++;
                    }
                })
                fields.forEach(field => {
                    if (field.type == 'checkbox') {
                        if (field.checked) {
                            counter++;
                        }
                    }

                    if (field.type == 'search') {
                        if (field.value !== '') {
                            counter++;
                        }
                    }
                    if (field.tagName.toLowerCase() === 'select') {
                        const selectedOption = field.choicesInstance.getValue();
                        if (selectedOption.length > 0) {
                            counter++;
                        }
                    }
                })
                if (counter >= 1) {
                    filter.querySelector('.count-active-filters span').innerHTML = counter;
                    filter.querySelector('.count-active-filters').classList.add('active')
                } else {
                    filter.querySelector('.count-active-filters').classList.remove('active')
                }
            })
        }


    }

    function download_file (fileURL, fileName) {
// for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
// window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

// for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }


};


document.addEventListener('DOMContentLoaded', function () {
    searchMapBlock()
}, false);

window.addEventListener('acf/loaded/search-map-block', function (e) {
    searchMapBlock()
});