import Choices from "choices.js";
import 'choices.js/public/assets/styles/choices.css';

const choicesSelect = function (el) {
    let choicesInstance;
    if (el.classList.contains('multiselect')) {
        choicesInstance = new Choices(el, {
            callbackOnCreateTemplates: function (template) {
                return {
                    choice: ({classNames}, data) => {
                        const dataTarget = Object.keys(data.customProperties).length > 0 ? data.customProperties.class : '';
                        const dataSlug = Object.keys(data.customProperties).length > 0 ? data.customProperties.slug : '';
                        const customClass = dataTarget ? `target-${dataTarget}` : '';

                        return template(`
                              <div class="${classNames.item} ${classNames.itemChoice} ${
                            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                        } ${customClass} ${data.selected ? 'choices__is-selected' : ''}" data-select-text="${this.config.itemSelectText}" data-choice ${
                            data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'
                        } data-id="${data.id}" data-slug="${dataSlug}" data-value="${data.value}" ${
                            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                        } data-target="${dataTarget}">
                                 ${data.label}
                              </div>
                            `);
                    },
                };
            },
            shouldSort: false,
            allowHTML: true,
            resetScrollPosition: false,
            closeDropdownOnSelect: false,
            searchEnabled: false,
            searchChoices: false,
            placeholder: true,
            removeItems: false,
            placeholderValue: 'Retskreds',
            removeItemButton: false,
            searchPlaceholderValue: 'Retskreds',
            renderSelectedChoices: 'always',
            classNames: {
                highlightedState: 'is-highlighted',
                itemSelectable: 'is-selectable',
                placeholder: 'is-placeholder',
                itemDisabled: 'is-disabled',
                itemChoice: 'is-choice',
                listItems: ['choices__list--multiple'],
            },
        });

    } else {
        choicesInstance = new Choices(el, {
            callbackOnCreateTemplates: function (template) {
                return {
                    choice: ({classNames}, data) => {
                        const dataTarget = Object.keys(data.customProperties).length > 0 ? data.customProperties.class : '';
                        const dataSlug = Object.keys(data.customProperties).length > 0 ? data.customProperties.slug : '';
                        const customClass = dataTarget ? `target-${dataTarget}` : '';

                        return template(`
                              <div class="${classNames.item} ${classNames.itemChoice} ${
                            data.disabled ? classNames.itemDisabled : classNames.itemSelectable
                        } ${customClass}" data-select-text="${this.config.itemSelectText}" data-choice ${
                            data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'
                        } data-id="${data.id}" data-slug="${dataSlug}" data-value="${data.value}" ${
                            data.groupId > 0 ? 'role="treeitem"' : 'role="option"'
                        } data-target="${dataTarget}">
                                 ${data.label}
                              </div>
                            `);
                    },
                };
            },
            shouldSort: false,
            searchEnabled: false,
            allowHTML: true,
            searchChoices: false,
            placeholder: true,
            placeholderValue: 'Kommuner',
            searchPlaceholderValue: null,
            classNames: {
                highlightedState: 'is-highlighted',
                itemSelectable: 'is-selectable',
                placeholder: 'is-placeholder',
                itemDisabled: 'is-disabled',
                itemChoice: 'is-choice'
            },
        });
    }

    return choicesInstance;
}

export {choicesSelect}